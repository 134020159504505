import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { createMetaManager, plugin as vueMetaPlugin } from "vue-meta";

import "./main.css";

// .use(store)
createApp(App)
  .use(router)
  .use(vueMetaPlugin)
  .use(createMetaManager())
  .mount("#app");
