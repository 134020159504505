import { createRouter, createWebHistory, RouteLocationMatched, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import axios from "axios";
import HomeView from "../views/HomeView.vue";
import { LoaderService } from "@/services/loader.service";

// people
// my-films (TODO)
// articles
// cinema-listings
// search
// contact
// rss/out-this-week
// rss/coming-soon

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "Home",
    },
    props: true,
    component: HomeView,
    beforeEnter(to, from, next) {
      LoaderService.loadHomeFilms()
        .then((films: any) => {
          to.params = {
            ...to.params,
            ...films,
          };
          next();
        })
        .catch(_ => {
          next("/");
        });
    },
  },
  {
    path: "/films",
    name: "films",
    props: true,
    component: () => import(/* webpackChunkName: "films-view" */ "../views/FilmsView.vue"),
    children: [
      {
        path: "this-week",
        name: "this-week",
        props: true,
        component: () => import(/* webpackChunkName: "films-list" */ "../views/FilmListView.vue"),
      },
      {
        path: "coming-soon",
        name: "coming-soon",
        props: true,
        component: () => import(/* webpackChunkName: "films-list" */ "../views/FilmListView.vue"),
      },
      {
        path: "most-popular",
        name: "most-popular",
        props: true,
        component: () => import(/* webpackChunkName: "films-list" */ "../views/FilmListView.vue"),
      },
      {
        path: "now-showing",
        name: "now-showing",
        props: true,
        component: () => import(/* webpackChunkName: "film-list" */ "../views/FilmListView.vue"),
      },
      {
        path: "3d",
        name: "3d",
        props: true,
        component: () => import(/* webpackChunkName: "film-list" */ "../views/FilmListView.vue"),
      },
      {
        path: "all",
        name: "all",
        props: true,
        component: () => import(/* webpackChunkName: "film-list" */ "../views/FilmListView.vue"),
      },
      {
        path: "premieres",
        name: "premieres",
        props: true,
        component: () => import(/* webpackChunkName: "film-list" */ "../views/FilmListView.vue"),
      },
      {
        path: "documentaries",
        name: "documentaries",
        props: true,
        component: () => import(/* webpackChunkName: "film-list" */ "../views/FilmListView.vue"),
      },
      {
        path: "month/:year(\\d+)/:month(\\S+)",
        name: "month",
        props: true,
        component: () => import(/* webpackChunkName: "films-list" */ "../views/FilmListView.vue"),
      },
      {
        path: "year/:year(\\d+)",
        name: "year",
        props: true,
        component: () => import(/* webpackChunkName: "films-list" */ "../views/FilmListView.vue"),
      },
      {
        path: ":week(\\d{4}-\\d{2}-\\d{2})",
        name: "week",
        props: true,
        component: () => import(/* webpackChunkName: "films-list" */ "../views/FilmListView.vue"),
      },
      // films/by-date - special
      {
        path: ":id(\\d+)-:name(\\S+)",
        name: "film",
        props: true,
        component: () => import(/* webpackChunkName: "film-full" */ "../views/FilmView.vue"),
      },
    ],
  },
  {
    path: "/f-:id(\\d+)",
    name: "Short Film URL",
    redirect: (to) => {
      return `/films/${to.params.id}-film/`;
    },
  },
  {
    path: "/people",
    name: "People",
    props: true,
    component: () => import(/* webpackChunkName: "person" */ "../views/PersonView.vue"), // TODO: PeopleView.vue
    children: [
      {
        path: ":id(\\d+)-:name(\\S+)",
        name: "person",
        props: true,
        component: () => import(/* webpackChunkName: "person" */ "../views/PersonView.vue"),
        strict: true,
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  strict: true,
});

router.beforeEach((to, from, next) => {
  console.log("EACH", to.name, to, from);

  // to.meta.title = "TEST";
  if (to.name === "person") {
    console.log("LOAD", to.name, to.params.id);
    LoaderService.loadPerson(parseInt(String(to.params.id), 10))
      .then((person: any) => {
        // TODO: app loading
        to.params = {
          ...to.params,
          person,
        };

        console.log(person);

        document.title = `${person.name} | UK Cinema Release Date`;

        next();
      })
      .catch(_ => {
        next("/");
      });

    return;
  } else if (to.name === "film") {
    console.log("LOAD", to.name, to.params.id);
    LoaderService.loadFilm(parseInt(String(to.params.id), 10))
      .then((film: any) => {
        to.params = {
          ...to.params,
          film,
        };

        console.log("FILM", film);

        to.meta.title = `${film.name} | UK Cinema Release Date`;
        to.meta.description = `${film.name} UK Cinema release date, synopsis, official site, trailer, cinema listings, ratings, cast and reviews.`;
        to.meta.keywords = `${film.name.toLowerCase()},uk release date,synopsis,cast` + (film.aka ? `,${film.aka.toLowerCase()}` : "");
        to.meta["og:title"] = film.name;
        to.meta["og:type"] = "video.movie";
        to.meta["og:url"] = `https://www.filmdates.co.uk${film.url}`; // "http://www.filmdates.co.uk/films/1006737-fishermans-friends-one-and-all/";
        to.meta["og:image"] = "https://www.filmdates.co.uk/posters/h4yjd3jvuc.jpg";
        to.meta["og:description"] = to.meta.description;

        next();
      })
      .catch(error => {
        console.error("error", error);
        next("/");
      });

    return;
  } else if ([
    "this-week",
    "coming-soon",
    "most-popular",
    "week",
    "month",
    "year",
    "now-showing",
    "3d",
    "all",
    "premieres",
    "documentaries",
  ].indexOf(to.name as string) > -1) {
    LoaderService.loadFilms(to.name as string, to.params)
      .then((data: any) => {
        if (Array.isArray(data)) {
          data = {
            films: data,
            count: data.length,
          };
        }

        // TODO: app loading
        to.params = {
          ...to.params,
          name: to.name,
          params: to.params,
          ...data,
        };

        const title = (to.name as string).replace(/-/g, " ").replace(/(^| )[a-z]/g, s => s.toUpperCase());

        console.log("FILMS", data.films);

        to.meta.title = `${title} | UK Cinema Release Date`;

        next();
      })
      .catch(_ => {
        next("/");
      });

    return;
  }

  next();
});

router.afterEach((to: RouteLocationNormalized, from) => {
  console.log("AFTER", to);

  if (to.meta.title) {
    document.title = to.meta.title as string;
  }

  document.getElementsByTagName("head")[0].querySelectorAll("meta.dynamic").forEach(element => element.parentElement?.removeChild(element));

  for (const [name, value] of Object.entries(to.meta)) { // eslint-disable-line comma-dangle
    if (name === "title") {
      document.title = value as string;
    } else {
      const meta = document.createElement("meta") as any;

      if (name.startsWith("og:")) {
        meta.property = name;
      } else {
        meta.name = name;
      }

      meta.content = value as string;
      meta.className = "dynamic";
      document.getElementsByTagName("head")[0].appendChild(meta);
    }
  }
});

/*
<meta name="description" content="Fisherman's Friends: One and All UK Cinema release date, synopsis, official site, trailer, cinema listings, ratings, cast and reviews."/>
<meta name="keywords" content="fisherman's friends: one and all,uk release date,synopsis,cast,fisherman's friends 2"/>
<meta property="og:title" content="Fisherman's Friends: One and All"/>
<meta property="og:type" content="video.movie"/>
<meta property="og:url" content="http://www.filmdates.co.uk/films/1006737-fishermans-friends-one-and-all/"/>
<meta property="og:image" content="http://www.filmdates.co.uk/posters/h4yjd3jvuc.jpg"/>
<meta property="og:description" content="Fisherman's Friends: One and All UK Cinema release date, synopsis, official site, trailer, cinema listings, ratings, cast and reviews."/>

<link rel="canonical" href="http://www.filmdates.co.uk/films/1006737-fishermans-friends-one-and-all/"/>

<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
<meta name="google-site-verification"
content="-4-EEus9sLF7d5MVsFwTmHy3T0uBvoCCspFnGj4--y8" />
<meta property="og:site_name" content="FilmDates.co.uk"/>
<meta name="author" content="www.filmdates.co.uk" />
<meta name="viewport" content="width=device-width, initial-scale=0.5" />
<meta name="geo.region" content="GB-SRY" />
<meta name="geo.placename" content="Guildford" />
<meta name="geo.position" content="51.22;-0.497" />
<meta name="ICBM" content="51.22,-0.497" />
<link rel="alternate" type="application/rss+xml"
    title="RSS Feed for new films out this week" href="/rss/out-this-week/" />
<link rel="alternate" type="application/rss+xml"
    title="RSS Feed for new films coming soon" href="/rss/coming-soon/" />
<link rel="icon" href="https://www.filmdates.co.uk/favicon.ico" type="image/x-icon" />
<link rel="shortcut icon" href="https://www.filmdates.co.uk/favicon.ico"
    type="image/x-icon" />
<link rel="apple-touch-icon" href="https://images.filmdates.co.uk/apple-touch-icon.png" />
*/

export default router;
