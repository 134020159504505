import moment from "moment";

export interface ISocialLink {
  url: string;
  linkClass: string;
  title: string;
  iconClass: string;
}

export function formatReleaseDate(dateString: string, short: boolean = false): string {
  const date = moment.utc(dateString);
  const format = short ? "dddd Do MMM 'YY" : "dddd Do MMMM YYYY";

  return date.format(format).replace(/([0-9]+)(st|nd|rd|th)/, "$1<sup>$2</sup>");
}

export function getFilmSocialLinks(filmName: string, url: string, shortUrl: string): ISocialLink[] {
  const urlEncodedUrl = encodeURIComponent(url);
  const urlEncodedText = encodeURIComponent(`FilmDates.co.uk - ${filmName}`);
  const urlEncodedPosterUrl = "http%3A%2F%2Fwww.filmdates.co.uk%2Fposters%2Fh21dhpxlcf.jpg";

  return [
    {
      url: `https://twitter.com/intent/tweet?text=${shortUrl}%20${urlEncodedText}`,
      linkClass: "fs-twttr",
      title: `Tweet about ${filmName} on Twitter`,
      iconClass: "fa-twitter",
    },
    {
      url: `http://www.stumbleupon.com/submit?url==${urlEncodedUrl}&amp;title=${urlEncodedText}`,
      linkClass: "fs-stmbl",
      title: `Share ${filmName} on StumbleUpon`,
      iconClass: "fa-stumbleupon",
    },
    {
      url: `http://reddit.com/submit?url==${urlEncodedUrl}&amp;title=${urlEncodedText}`,
      linkClass: "fs-rddt",
      title: `Share ${filmName} on Reddit`,
      iconClass: "fa-reddit",
    },
    {
      url: `https://plus.google.com/share?url=${urlEncodedUrl}`,
      linkClass: "fs-ggl",
      title: `Share ${filmName} on Google+`,
      iconClass: "fa-google-plus-g",
    },
    {
      // url: `http://www.facebook.com/sharer/sharer.php?s=100&amp;p%5Burl%5D=${urlEncodedUrl}&amp;p%5Bimages%5D%5B0%5D=${urlEncodedPosterUrl}&amp;p%5Btitle%5D=${urlEncodedText}&amp;p%5Bsummary%5D=`,
      url: `http://www.facebook.com/sharer/sharer.php?u=${urlEncodedUrl}`,
      linkClass: "fs-fb",
      title: `Share ${filmName} on Facebook`,
      iconClass: "fa-facebook",
    },
    {
      url: `http://digg.com/submit?phase=2&amp;url=${urlEncodedUrl}&amp;title=${urlEncodedText}`,
      linkClass: "fs-dgg",
      title: `Share ${filmName} on Digg`,
      iconClass: "fa-digg",
    },
    {
      url: `http://del.icio.us/post?url=${urlEncodedUrl}&amp;title=${urlEncodedText}`,
      linkClass: "fs-dlcs",
      title: `Share ${filmName} on Delicious`,
      iconClass: "fa-delicious",
    },
    {
      url: `https://wa.me/?text=${urlEncodedUrl}`,
      linkClass: "fs-whtspp",
      title: `Send a WhatsApp message about ${filmName}`,
      iconClass: "fa-whatsapp",
    },
  ];
}
