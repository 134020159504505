import axios from "axios";
import { Observable } from "rxjs";

export class SearchService {
  private static cache: any = {};

  public static performSearch(searchQuery: string): Observable<any[]> {
    // return of([
    //   {
    //     name: "Test",
    //     type: "Film",
    //     path: "/films",
    //   },
    // ]);
    return new Observable((observer) => {
      const cacheKey = searchQuery.toLowerCase().trim();

      if (this.cache[searchQuery]) {
        observer.next(this.cache[cacheKey].results);

        return;
      }

      axios.get(`${process.env.VUE_APP_API_BASE_URL}/search/${encodeURIComponent(searchQuery)}`)
        .then((response) => {
          this.cache[cacheKey] = {
            ...response.data,
            dtm: Date.now(),
          };
          observer.next(response.data.results);
        })
        .catch(() => {
          observer.error();
        });
    });
  }
}
