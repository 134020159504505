import axios from "axios";
import moment from "moment";
import { RouteParams } from "vue-router";

export class LoaderService {
  public static loadHomeFilms(): Promise<any> {
    return Promise
      .all([
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/films/this-week`),
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/films/coming-soon`),
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/films/most-popular`),
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/films/film-of-the-week`),
      ])
      .then(([
        thisWeek,
        comingSoon,
        mostPopular,
        fow,
      ]) => {
        return {
          films: {
            thisWeek: thisWeek.data.filter((film: { id: number; }) => film.id !== fow.data.id),
            comingSoon: comingSoon.data,
            mostPopular: mostPopular.data,
            fow: fow.data,
          },
        };
      });
  }

  public static loadFilm(id: number): Promise<any> {
    return axios.get(`${process.env.VUE_APP_API_BASE_URL}/films/${id}`)
      .then(response => {
        return response.data;
      });
  }

  public static loadFilms(pageName: string, params?: RouteParams): Promise<any> {
    if ([
      "this-week",
      "coming-soon",
      "most-popular",
    ].indexOf(pageName) > -1) {
      return axios.get(`${process.env.VUE_APP_API_BASE_URL}/films/${pageName}`).then(response => response.data);
    }

    const searchParams = new URLSearchParams({
      page: String(params?.page || 1),
    });
    let url = `${process.env.VUE_APP_API_BASE_URL}/films`;

    if (pageName === "week" || pageName === "month" || pageName === "year") {
      let from, to;

      if (pageName === "week") {
        from = moment.utc(params?.week).startOf("isoWeek");
        to = moment.utc(from).add(1, "week").subtract(1, "day");
      } else if (pageName === "month") {
        from = moment.utc(`${params?.year}-${params?.month}-01`).startOf("month");
        to = moment.utc(from).add(1, "month").subtract(1, "day");
      } else if (pageName === "year") {
        from = moment.utc(`${params?.year}-01-01`).startOf("year");
        to = moment.utc(from).add(1, "year").subtract(1, "day");
      }

      searchParams.append("from", from?.format("YYYY-MM-DD") as string);
      searchParams.append("to", to?.format("YYYY-MM-DD") as string);
    } else if (pageName === "premieres") {
      searchParams.append("premieres", "true");
    } else if (pageName === "now-showing") {
      searchParams.append("showing", "true");
    } else if (pageName === "most-popular") {
      searchParams.append("popular", "true");
    } else if (pageName === "3d") {
      searchParams.append("3d", "true");
    } else if (pageName === "documentaries") {
      searchParams.append("genre", "documentary");
    }

    url = `${url}?${searchParams.toString()}`;

    return axios.get(url).then(response => response.data);

    // return Promise.resolve({
    //   films: [],
    // });
  }

  public static loadPerson(id: number): Promise<any> {
    return axios.get(`${process.env.VUE_APP_API_BASE_URL}/people/${id}`)
      .then(response => {
        return response.data;
      });
  }
}
